import { Auth0Provider as A0Provider, AppState as A0AppState } from '@auth0/auth0-react';
import omit from 'lodash/omit';
import querystring from 'query-string';
import { FC, ReactNode } from 'react';

import { EnvConfig } from '@/env_config';

interface Props {
	children: ReactNode;
}

export const onRedirectCallback = (appState?: A0AppState | undefined): void => {
	const query = appState?.query ?? {};
	const returnTo = query?.returnTo ?? '/';
	const newQuery = omit(query, ['returnTo', 'code', 'state']);
	const pathname = decodeURIComponent(String(returnTo));
	const search = `?${querystring.stringify(newQuery)}`;
	window.location.assign(`${pathname}${search}`);
};

const getRedirectUri = (): string => {
	// In dev environments, take the user back to where they started.
	// This will allow E2E tests which intentionally avoid the CDN domain to stay off of the CDN domain
	if (process.env.LOG_ENV === 'dev' && typeof window !== 'undefined' && window.location.origin) {
		return window.location.origin;
	}
	return EnvConfig.partnerPortalUrl;
};

export default function Auth0Provider({ children }: Props): ReturnType<FC> {
	return (
		<A0Provider
			domain={EnvConfig.auth0Domain}
			clientId={EnvConfig.auth0ClientId}
			authorizationParams={{
				redirect_uri: `${getRedirectUri()}/`,
			}}
			cacheLocation="localstorage"
			useRefreshTokens={true}
			onRedirectCallback={onRedirectCallback}
		>
			{children}
		</A0Provider>
	);
}
